<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
      <DebitOrder v-if="showMethod && value.recurringDonationType === 'DEBIT_ORDER'" v-model:donorBankAccount="value.donorBankAccount" v-model="value"/>
      <CreditCard v-else-if="showMethod && value.recurringDonationType === 'CREDIT_CARD'" v-model:frequency="value.frequency" v-model:startDate="value.startDate" v-model:debitOrderDay="value.debitOrderDay" />
      <SelectPaymentMethod v-else v-model="value.recurringDonationType" @methodSelected="methodSelected" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SelectPaymentMethod: defineAsyncComponent(() => import('./views/paymentdetails/SelectPaymentMethod.vue')),
    DebitOrder: defineAsyncComponent(() => import('./views/paymentdetails/debitOrder/DebitOrder.vue')),
    CreditCard: defineAsyncComponent(() => import('./views/paymentdetails/creditCard/CreditCard.vue'))
  },
  name: 'Wizard 2',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      showMethod: false
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    methodSelected () {
      console.log('METHIOD', this.value.recurringDonationType)
      this.showMethod = true
    }
  }
}

</script>
